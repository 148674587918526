<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Archived Products</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Archived Products"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto"></v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="products"
        no-data-text="No Archived Products found"
      >
        <template v-slot:item.price="{ item }"> £{{ item.price }} </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
                @click="openRestore(item)"
              >
                <v-icon x-small>mdi-restore</v-icon>
              </v-btn>
            </template>
            <span>Restore</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog v-model="restoreDialog.open" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Restore Product</v-card-title>
        <v-card-text
          >Are you sure you want to restore
          {{ restoreDialog.product.name }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetRestore"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="restoreDialog.loading"
            @click="saveRestore"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Products",
          disabled: false,
          exact: true,
          to: {
            name: "module-flightmode-products",
          },
        },
        {
          text: "Archived",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Product", value: "name" },
        { text: "Category", value: "category.name" },
        { text: "Price", value: "price" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      restoreDialog: {
        open: false,
        loading: false,
        product: {},
      },
    };
  },

  computed: {
    products() {
      let products = this.$store.state.flightmode.products["archived"];
      const searchTerm = this.searchTerm.toLowerCase();

      if (this.searchTerm !== "") {
        products = products.filter((p) => {
          const name = p.name.toLowerCase();
          const category = p.category.name.toLowerCase();

          return name.includes(searchTerm) || category.includes(searchTerm);
        });
      }

      return products;
    },
  },

  methods: {
    openRestore(product) {
      this.restoreDialog.product = product;
      this.restoreDialog.open = true;
    },

    resetRestore() {
      this.restoreDialog.loading = false;
      this.restoreDialog.open = false;
      this.restoreDialog.product = {};
    },

    saveRestore() {
      const appId = this.$route.params.id;
      this.restoreDialog.loading = true;

      this.$store
        .dispatch("flightmode/products/restoreProduct", {
          appId,
          productId: this.restoreDialog.product.id,
        })
        .then(() => this.resetRestore())
        .catch(() => (this.loading = false));
    },
  },
};
</script>
